<template>
  <f7-page class="influencerview-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_INFLUENCER')" type="back" :settings="false" />
    </template>
  </f7-page>
</template>

<script>
import { defineComponent, defineAsyncComponent } from "vue";
import { useStore } from "@/store";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));

export default defineComponent({
  name: "InfluencerListPage",
  components: {
    ProfileNavigationComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const store = useStore();

    return {};
  },
});
</script>
